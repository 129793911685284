import { translate } from "vue-gettext";
const { gettext: $gettext } = translate;
import Swal from "sweetalert2";

export const msgBoxConfirm = (message = null, options = {}) => {
  message = message || $gettext("Are you sure you want to delete this item?");
  options = {
    title: "Confirmation",
    text: message,
    icon: "question",
    confirmButtonText: $gettext("Yes"),
    showCancelButton: true,
    cancelButtonText: $gettext("Cancel"),
    confirmButtonColor: "#9176cc",
    ...options
  };
  return Swal.fire(options);
};
